<template>
  <div v-if="loading">
    <img class="load" src="../assets/Spin.png" alt="">
  </div>
  <div v-else class="product">  
    <div class="img">
        <img :src="prodcutdtails.image" >
        <h3>{{prodcutdtails.title}}</h3>
        <h3>{{prodcutdtails.price}}/-</h3>
    </div>
    <div class="descr">
        <h3>{{prodcutdtails.description}}</h3>
        <button @click="addcart">Add to cart</button><br>
        <button @click="razorpayinitializer">Buy now</button><br>
    </div>
  </div>
</template>



<script>

import { env } from "process";


export default {
    
    props:['id'],
    data(){
        return{
            prodcutdtails:'',
            loading:'true'
        }
    },
    methods:{
      // 
      // 
      async razorpayinitializer(){
        try {
            const response = await fetch('https://razorpayordercreate.onrender.com/create-order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    customer_id: 'c9ebf5f0-74bc-478e-bc24-45d453f80581',
                    order_quantity: 1,
                    product_id: this.prodcutdtails.id,
                    shipping_address: 'Rangampeta, Tirupati, Andhra Pradesh, 517102'
                }) 
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const orderData = await response.json();
            const orderId = orderData.order.id;
            const amount = orderData.order.amount;

            // Setting up Razorpay options with the fetched order ID
            var options = {
                "amount":amount,
                "key": env.YOUR_KEY_ID, // Enter the Key ID 
                "currency": "INR",
                "name": "Teakwood Furniture",
                "description": "Test Transaction",
                "image": "https://teakwoodfurniturepoint.online/img/logo.7bff62d8.png",
                "order_id": orderId,
                "callback_url": "https://teakwoodfurniturepoint.online/",
                "prefill": {
                    "name": "Gaurav Kumar",
                    "email": "gaurav.kumar@example.com",
                    "contact": "9000090000"
                },
                "notes": {
                    "address": "Razorpay Corporate Office"
                },
                "theme": {
                    "color": "#3399cc"
                },
                "handler": async function (response) {
                    const paymentId = response.razorpay_payment_id;
                    const signature = response.razorpay_signature;

                    // Log values for debugging
                    console.log("Payment ID:", paymentId);
                    console.log("Signature:", signature);
                    console.log("Order ID:", orderId); // Use the captured orderId here

                    // Send payment details to your backend for verification
                    const verificationResponse = await fetch('https://razorpayordercreate.onrender.com/payment-verification', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            order_id: orderId,
                            payment_id: paymentId,
                            signature: signature
                        })
                    });

                    // Handle the verification response
                    const verificationResult = await verificationResponse.json();
                    if (verificationResponse.ok) {
                        alert('Payment Successful! Order has been verified.');
                        console.log('Payment Successful! Order has been verified.');
                        window.location.href = "https://teakwoodfurniturepoint.online/"; 
                    } else {
                        alert('Payment could not be verified: ' + verificationResult);
                        console.log('Payment could not be verified: ' + verificationResult);
                    }
                },
                "modal": {
                    "ondismiss": async function() {
                        console.log("Payment window closed");
                        // Delete order when payment window is canceled
                        await fetch('https://razorpayordercreate.onrender.com/cancel-order', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ order_id: orderId })
                        });
                    }
}
            };

            // Opening the Razorpay modal
            var rzp1 = new Razorpay(options);
            rzp1.open();

        } catch (error) {
            console.error('Error fetching order ID:', error);
            alert('Failed to create order. Please try again later.');
        }
      },

      async addcart(){
        try {
        const response = await fetch('https://razorpayordercreate.onrender.com/products/addcart/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            // userId: this.$store.state.userid, // Pass the user ID from props
            userId: 'c9ebf5f0-74bc-478e-bc24-45d453f80581', // Pass the user ID from props
            productId: this.$route.params.id, // Pass the product ID from props
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to add to cart');
        }

        const result = await response.json();
        this.message = `Product added to cart successfully. Quantity: ${result.quantity}`;
      } catch (error) {
        console.error('Error adding to cart:', error);
        this.message = 'Error adding to cart. Please try again.';
      }

    },
        async load(){

          try {
            const response = await fetch(`https://razorpayordercreate.onrender.com/products/${this.$route.params.id}`);
            
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            
            this.prodcutdtails = await response.json();
            this.loading = false;
          } catch (error) {
            console.error('Error fetching product details:', error);
            this.loading = false; // Set loading to false even if there's an error
            }
        this.loading=false
        }
        
    },
    async mounted(){
        await this.load()
    }
}
</script>






<style scoped>
h3{
    margin-top: 30px;
}

 img{
    margin-top: 40px;
    max-width: 500px;
 }

 .img{
    align-self: center;
 }

.product{
    display: grid;
    grid-template-columns:1.5fr 1fr;
    /* margin: auto 0px; */
    height: 90vh;
}

.load{
    width: 100px;
    margin-top:30vh ;
}

.descr{
    margin: auto 0px;
    margin-right: 100px;
}

button{
    font-size: 30px;
    width: 200px;
    color: rgba(0, 0, 0, 0.599);
    margin-top: 50px;
    border: none;
    padding: 10px;
    background: wheat;
    border-radius: 8px;
}

button:hover{
    cursor: pointer;
}

</style>