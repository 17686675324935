<template>
    <transition>
        <div class="cart">
                <div class="empty" v-if="isempty">       
                <h2>No Items in your cart</h2>
                <h2>Add Items to your cart</h2>
                </div>
                <section>
                <div class="items">
                    <div class="item" v-for="item in $store.state.cart" :key="item.id">
                        <img :src="item.image" alt="">
                        <div class="details">
                            <h3>{{item.title}}</h3>
                            <p class="description">{{item.description}}</p>
                            <select name="" id="">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                            </select>
                            <h3>₹{{item.price}}</h3>
                        </div>
                        <button @click="removeitem(item.id)" class="remove">&#x2715;</button>
                    </div>
                </div>
                <div class="checkout">
                    checkout
                </div>
                </section>

        </div>


    </transition>
</template>



<script>


export default {
    data(){
        return{
        }
    },
    methods:{
        togglecart(){
            this.$store.state.iscart=!this.$store.state.iscart
        },
        removeitem(id){
           const removed= this.$store.state.cart.filter(item=>item.id!==id)
           this.$store.state.cart=removed
        },

    },
    computed:{
        isempty(){
        if (this.$store.state.cart.length>0){
            return false
        }
        else{
           return true
        }
    }
    },
    async mounted(){
        if (this.$store.state.loggedin){
            console.log('ok')
        }
        else{
            this.$router.push('/login')
        }
        this.isempty
// 
//     
       
        try {
            const response = await fetch(`https://razorpayordercreate.onrender.comgetCartItems`);
            
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            
            this.prodcutdtails = await response.json();
            this.loading = false;
          } catch (error) {
            console.error('Error fetching product details:', error);
            this.loading = false; // Set loading to false even if there's an error
            }

// 
// 
    },

}
</script>



<style scoped>


.checkout{
    width: 35vw;
    background: wheat;
    margin: 50px 0px;
    height: 200px;
}

.empty{
    position: relative;
    margin-top: 225px;
}

select{
    padding: 6px;
    font-size: 16px;
}

.description{
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 700px;
}

/* option{
    border-radius: 0px;
    margin: 10px;
} */

.remove{
    border: 0px;
    background: none;
    font-size: 20px;
    position:absolute ;
    top: 10px;
    right: 10px;
}

.remove:hover{
    cursor: pointer;
}

section{
    margin-top:80px;
    display: flex;
}

.empty{
     width: 50vw;
}

.item{
    margin:50px ;
    border:1px solid;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 35px;
    position: relative;
    width: 50vw;
    height: 200px;
    column-gap: 40px;
}

.details{
    display: flex;
    flex-direction: column;
    row-gap:25px;
    align-items: flex-start;
    justify-content: center;
    
}


img{
    max-height:200px;
    max-width:200px;
}


.cart{
    display: flex;
  /* width: 30vw;
  height:80vh;
  min-height: 500px;
  background: rgb(241, 227, 199);
  position: fixed;
  top: 0px;
  right: 0px;
  overflow-y: scroll; */
}


.close:hover{
    cursor: pointer;
}

</style>